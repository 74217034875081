@import 'reset.sass'
@import 'global.sass'

@font-face
  font-family: 'berlin-sans-fb-demi-bold'
  src: url('./Assets/Font-Style/FontsFree-Net-Mont-Bold.ttf') format('truetype')
  font-weight: normal
  font-style: normal

body
  overflow-x: hidden
  color: white
  scroll-behavior: smooth
  background: black
  & iframe
    display: none
  &::-webkit-scrollbar
    width: .8rem
  &::-webkit-scrollbar-track
    background: rgba(0, 0, 0, 0.8)
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.068)
    border-radius: 10px
  &::-webkit-scrollbar-thumb
    background: #fd115cb6
    box-shadow: inset 0 0 5px #fd115cb6
    background-clip: content-box
    border: 4px solid black
    border-radius: 1rem
.dark-theme
  background: #111111
.light-theme
  background: #fbfbfb

.center_align_container
  height: 100%
  width: 100%
  display: flex
  align-items: center
  justify-content: center

.header
    width: 100%
    height: 11vh
    right: 0
    padding: 1rem 1rem
    @media screen and (max-width: 768px)
        width: 95%
    .parent
        position: relative
        background: transparent
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: flex-start
        gap: 1rem
        // @media screen and (max-width: 768px)
        //      margin: 0.5rem
        .logo
            height: 3rem
            width: auto
            object-fit: contain
            @media screen and (max-width: 768px)
                height: 1.5rem
        .title
            color: #fd115c
            font-size: 1.2rem
            font-weight: bold
            font-family: 'berlin-sans-fb-demi-bold', sans-serif
            text-transform: capitalize
            text-align: center


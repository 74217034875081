.container {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    height: 100%;
    background: transparent;
}

.navbar {
    flex-grow: 1;
    background-color: transparent;
    position: relative;
    width: 100%;
    height: 100%;
    .nav-buttons {
        font-weight: bold;
        font-size: 1em;
        text-transform: capitalize;
        transition: all .3s ease-in-out;
        font-family: 'berlin-sans-fb-demi-bold', sans-serif;
    }

    .dark-hover {
        color: rgba(255, 255, 255, .5);
        &:hover {
            color: white;
            background: transparent;
        }
    }

    .light-hover {
        color: rgba(0, 0, 0, .5);
        &:hover {
            color: black;
            background: transparent;
        }
    }

    .play-button {
        background-color: #fd115c;
        border-radius: 1rem;
        padding: 0 1.5rem 0 1.5rem;
        color: white;
        font-weight: bold;
        font-size: 1.2em;
        text-transform: capitalize;
        transition: all .3s ease-in-out;
        font-family: 'berlin-sans-fb-demi-bold', sans-serif;
        align-self: flex-end;

        &:hover {
            background-color: #fd115cb6;
        }
    }

    .darkmode {
        background: #fd115c;
        border-radius: .5rem;
        padding: 0.3rem 0.5rem;
        border-radius: 10rem;
        &:hover {
            background: #fd115cb6;
        }
    }
}

.menu-icon {
    display: none;
}

.nav-elements ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    list-style-type: none;
    background-color: transparent;
}

.nav-elements ul a {
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    background-color: transparent;
}

.nav-elements ul a.active {
    font-weight: 500;
    position: relative;
    background-color: transparent;
}

.nav-elements ul a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: transparent;
}

.menu-button {
    color: white;
}

@media (max-width: 768px) {
    .nav-elements ul li:not(:last-child) {
        margin-right: 8px;
    }
    .nav-elements ul li:not(:nth-last-child(2)):not(:last-child) {
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, .5);
    }
    .theme-light{
        border-bottom: 1px solid rgba(0, 0, 0, .5);
    }
    .nav-elements ul li:first-child {
        margin-top: 1rem;
    }
}

@media (max-width: 768px) {
    .menu-icon {
        z-index: 2;
        display: block;
        cursor: pointer;
        width: 5vw;
        height: 5vh;
        justify-self: center;
        align-self: center;
    }

    .nav-elements {
        z-index: 2;
        position: absolute;
        right: -8vw;
        top: 100%;
        background-color: rgba(0, 0, 0, 1);
        width: 0;
        height: 100vh;
        transition: all 0.3s ease;
        transition-duration: .8s;
        overflow: auto;
        border-radius: 1rem;
        font-size: 1em;
        padding-bottom: 3rem;
        .play-button {
            align-self: center;
        }
    }

    .nav-elements.active {
        width: 200px;
    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        margin-left: 1em;
    }

    .nav-elements ul li {
        margin-right: unset;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .nav-elements ul li:not(:last-child) {
        margin-right: 1vw;
    }

    .menu-icon {
        z-index: 2;
        display: block;
        cursor: pointer;
        width: 5vw;
        height: 5vh;
    }

    .nav-elements {
        z-index: 2;
        position: absolute;
        right: -1vw;
        top: 100%;
        background-color: rgba(0, 0, 0, 1);
        width: 0;
        height: auto;
        transition: all 0.3s ease 0.8s;
        overflow: auto;
        border-radius: 1rem;
        font-size: 1em;
        padding-bottom: 1rem;

        .play-button {
            align-self: center;
        }
    }

    .nav-elements.active {
        width: 200px;
    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        margin-left: 1em;
    }

    .nav-elements ul li {
        margin-right: unset;
    }
}
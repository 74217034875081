.slide-in
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100vh
    background: rgba(0,0,0,.9)
    transform-origin: bottom
    z-index: 2
.slide-out
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100vh
    background: rgba(0,0,0,.9)
    transform-origin: top
    z-index: 2
